/* 
 * Postcodes String Array
 * 
 * @returns Postcodes : String Array
 */

const Postcodes = [
	"GB",
	"US",
	"AU",
	"CA",
	"DE",
	"NL",
	"PL",
	"FI",
	"FR",
	"DE",
	"CH",
	"CN",
	"SE",
	"IE",
	"LU",
	"NO",
	"BE",
	"AT",
	"PL",
	"SG",
	"KR",
	"NZ",
	"JP",
	"MY",
	"AR",
	"ZA",
	"TW",
	"TH"
];

export default Postcodes;
