/**
* Blood Thank You Page
* 
* 
* @returns BloodThankYouPage : PureComponent
*/

import "./BloodThankYouPage.scss";
import { BsArrowLeftSquareFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";

const BloodThankYouPage = () => {


    return(
        <section className="blood-thank-you">
        <NavLink to="/blood-submissions" className="blood-form-link"style={{ fontSize: '20px' }}><BsArrowLeftSquareFill />Submit Another Sample</NavLink>
        <br />
            <h1>Thank you for completing your blood submission.</h1>
            <h3>We've now received your details, and this has now been added to our secure database, ready to match up with your sample in our labs when it arrives.</h3>
            <p>Please follow the instructions thoroughly, to take your sample, and return it to us for accurate testing. We’ll send you an email when it’s here, so that we can begin the process.</p>
            <b>Please note: all the blood spots on your card need to be fully filled out for the test to be viable.</b>
            <p>Should you have any questions about the process, please contact us at: <a href="mailto:info@healthystuff.com">info@healthystuff.com</a></p>
        </section>
    );
}

export default BloodThankYouPage;