/**
 * 404 page, used as a fallback route when all other routes fail
 * Will offer users a way to redirect to the home page if this page renders
 * 
 * @returns Page404 : PureComponent
 */

import "./404Page.scss";
import { NavLink } from "react-router-dom";

const Page404 = () => {

    return(
        <section className="page-404">
            <h1>Error: Page not found</h1>
            <NavLink to="/">
                Go back to the home page
            </NavLink>
        </section>
    );
};

export default Page404;