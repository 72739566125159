/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * HairSubmissionThankYou Page Component
 * Renders the thank you page when form submissions are complete
 * This occurs when the hair submission form completes its submission
 * 
 * @method openInNewTab : (url : string) => void
 * @returns HarSubmissionThankYouPage : PureComponent
 */

import './HairSubmissionThankYouPage.scss';
import '../HairInstructionsPage.scss';
import { AiOutlineCloudDownload } from "react-icons/ai";
import takeBlueLogo from "../../assets/take-blue.png";
import storeBlueLogo from "../../assets/store-blue.png";
import printBlueLogo from "../../assets/print-blue.png";
import resultsBlueLogo from "../../assets/results-blue.png";
import { Link, useParams } from 'react-router-dom';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { BsArrowLeftSquareFill } from "react-icons/bs";
import Brands from "../../../consts/Brands";

const HairSubmissionThankYouPage = () => {

	const submissionCode = useParams().code;

	const navigate = useNavigate();

	// Set branding details
	const [brand, setBrand] = useState<any>((Brands as any)["default"]);
	const [reTest, setReTest] = useState<boolean>(false);

	// Open FPDF in a new page so that it automatically triggers the download by rendering the raw PHP file
	const downloadForm = () => {
		window.open(`/api/download/Pdf.php?submissionCode=${submissionCode}`, "_blank", "noreferrer");
	};

	useEffect(() => {
		const validateSubmissionCode = () => {

			if (submissionCode) {
				let orderNumber = "";

				let code = submissionCode;
				if (code.lastIndexOf("-R") === code.length - 2) {
					code = code.substring(0, code.length - 2);
					setReTest(true);
				} else {
					setReTest(false);
				}

				Object.keys(Brands).sort().forEach((key) => {
					if (code.indexOf(key) === 0) {
						orderNumber = code.substring(key.length);
						setBrand((Brands as any)[key]);
					}
				});

				if (orderNumber !== "") {
					return /^[0-9]+-[0-9]+$/.test(orderNumber);
				}
			}

			return false;
		};

		if (!validateSubmissionCode()) {
			navigate("/hair/thank-you/");
		}

	}, [submissionCode, navigate]);

	const createAddress = (brand: any) => {
		return (
			<>
				{brand.address1 &&
					<p><b>{brand.address1}</b></p>
				}

				{brand.address2 &&
					<p><b>{brand.address2}</b></p>
				}

				{brand.address3 &&
					<p><b>{brand.address3}</b></p>
				}

				{brand.address4 &&
					<p><b>{brand.address4}</b></p>
				}

				{brand.city &&
					<p><b>{brand.city}</b></p>
				}

				{brand.state &&
					<p><b>{brand.state}</b></p>
				}

				{brand.postcode &&
					<p><b>{brand.postcode}</b></p>
				}

				{brand.country &&
					<p><b>{brand.country}</b></p>
				}
			</>
		);
	}

	return (
		<section className="thank-you">

			<img
				src={brand.logo}
				alt={brand.name + " Logo"}
				className="app__logo"
			/>

			<div className="thank-you__title">
				<h3>Thank you for completing the submission form, please print and send this document along with your sample. </h3>
				{reTest && <></>}
				<button className="hair-form__submit" onClick={downloadForm}>Download Printout</button>
			</div>

			<div className="thank-you__subtitle">
				<h3>ABOUT YOUR {brand.name.toUpperCase()} {(brand.name.toUpperCase().lastIndexOf(" TEST") === brand.name.length - 5) ? "" : "TEST"} – PLEASE READ CAREFULLY:</h3>
				<p>The following four steps are on the form for you to print and submit with your sample. Please read each step carefully. When you’re ready to take your sample, please ensure you have the following things ready: scissors, a sealable see-through bag, and a pen.</p>
			</div>

			<ul className="thank-you__steps">

				<li className="thank-you__step">

					<img
						src={takeBlueLogo}
						alt="take your test blue logo"
						width="60"
						height="60"
					/>

					<div>
						<h3>Step 1 | Taking Your Sample</h3>
						<p>Using your forefinger and thumb gather 3 or 4 strands and slightly twist the hair together. This will make cutting the hair sample much easier and keep all the hair strand in the same direction. Try to cut the hair sample as close to the scalp as possible, using one cut. We do not need the follicle (bulb) for the testing.</p>
						<br />
						<p><b>Guidance</b> | The sample does not need to be from your head; we can test any body hair. Hair dye, perming or other treatments do not affect the bio-resonance testing process.</p>
					</div>
				</li>

				<li className="thank-you__step">
					<img
						src={storeBlueLogo}
						alt="store your test blue logo"
						width="60"
						height="60"
					/>
					<div>
						<h3>Step 2 | Storing Your Sample</h3>
						<p>Please seal just the hair sample in the see-through bag to ensure it stays dry and secure during transit. Ensure your samples clearly labelled, write your submission code (displayed on the printed form) and the patients name on the sample will ensure no delays.</p>
						<br />
						<p><b>Guidance</b> | We cannot test samples that are loose, or in metal foil or metallic packaging. Please be careful not to include other items in the same bag as your sample as this can affect testing. Each person submitting samples will have a code, so ensure that each person has their code on their sample.</p>
					</div>
				</li>

				<li className="thank-you__step">
					<img
						src={printBlueLogo}
						alt="print your test blue logo"
						width="60"
						height="60"
					/>
					<div>
						<h3>Step 3 | Sending Your Sample</h3>
						<p>Please print this form for each sample being submitted and cut off the barcode to be enclosed with your sample. Place hair samples in a secure envelope or postage wallet and post to the following address.</p>
						<br />
						<p><b>Guidance</b> | If you do not have a printer, we can accept your sample as long as it’s clearly labelled with the patients name AND the submission code. Please ensure correct postage is paid; incorrect postage can cause major delays to the processing time. {brand.name} are not liable to pay additional fees, your package may be returned.</p>
						<br />
						<p>Please send your sample to the address below:</p>
						<br />
						<p><b>{brand.name}</b></p>
						{createAddress(brand)}
					</div>
				</li>

				<li className="thank-you__step">
					<img
						src={resultsBlueLogo}
						alt="get your results blue logo"
						width="60"
						height="60"
					/>
					<div>
						<h3>Step 4 | What happens next?</h3>
						<p>Once your sample is posted, it will be sent to the postal hub in your country before being collated in ‘priority medical samples’ postage and sent to the testing facility in the UK; please note this can take to 20-days in some extreme situations. Once received, we will then scan your barcode/enter your submission code, which sends you an email to let you know your sample has arrived. Our lab tech team will then process each sample, and your results will be sent via email to the address provided on the digital submission form.</p>
						<br />
						<p><b>Guidance</b> | It is important to note that bioresonance tests do not make a medical diagnosis nor are they intended to be a substitute for professional medical advice or treatment. If you are suffering from or suspect you have a medical condition you should consult a qualified practitioner in the first instance.</p>
					</div>
				</li>
			</ul>

		</section>
	);
};

export default HairSubmissionThankYouPage;
