/* 
 * Brands Object
 * 
 * @returns Brands : Object
 */

const Brands = {
	"default": {
		prefix: "",
		brandPrefix: "",
		countryPrefix: "",
		name: "Healthy Stuff",
		logo: "/api/fpdf/assets/healthystuff/hs_logo.png",
		color: "#0C7088",
		url: "https://www.healthystuff.com/contact",
		email: "info@healthystuff.com",
		address1: "1A Bonington Complex",
		address2: "Trent Lane Industrial Estate",
		address3: "",
		address4: "",
		city: "Derby",
		state: "",
		postcode: "DE74 2PY",
		country: "United Kingdom",
		tel: "01332 850085"
	},
	"AMZ-": {
		prefix: "AMZ",
		brandPrefix: "AMZ",
		countryPrefix: "",
		name: "Amazon",
		logo: "/api/fpdf/assets/amazon/amazon-logo.png",
		color: "#0C7088",
		url: "",
		email: "",
		address1: "",
		address2: "",
		address3: "",
		address4: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		tel: ""
	},
	"AT-": {
		prefix: "AT",
		brandPrefix: "AT",
		countryPrefix: "",
		name: "Allergy Test",
		logo: "/api/fpdf/assets/allergytest/at_logo.png",
		color: "#0C7088",
		url: "https://www.allergytest.co/contact-us/",
		email: "info@allergytest.co",
		address1: "870 N. Miramar Avenue",
		address2: "",
		address3: "",
		address4: "",
		city: "Indialantic",
		state: "Florida",
		postcode: "32903",
		country: "United States",
		tel: "1 832 369-0544"
	},
	"AT-AU-": {
		prefix: "AT-AU",
		brandPrefix: "AT",
		countryPrefix: "AU",
		name: "Allergy Test",
		logo: "/api/fpdf/assets/allergytest/at_logo.png",
		color: "#0C7088",
		url: "https://www.allergytestaustralia.com/contact-us/",
		email: "info@allergytest.co",
		address1: "PO Box 491",
		address2: "",
		address3: "",
		address4: "",
		city: "Kellyville",
		state: "New South Wales",
		postcode: "2155",
		country: "Australia",
		tel: "61 1800 196 618"
	},
	"AT-IE-": {
		prefix: "AT-IE",
		brandPrefix: "AT",
		countryPrefix: "IE",
		name: "Allergy Test",
		logo: "/api/fpdf/assets/allergytest/at_logo.png",
		color: "#0C7088",
		url: "https://www.allergytestireland.com/contact-us/",
		email: "info@allergytest.co",
		address1: "Bracken Road No 51",
		address2: "Carlisle Offices",
		address3: "",
		address4: "",
		city: "Sandyford",
		state: "Dublin",
		postcode: "D18 CV48",
		country: "Ireland",
		tel: "1800 816 057"
	},
	"GTL-UK": {
		prefix: "GTL-UK",
		brandPrefix: "GTL",
		countryPrefix: "UK",
		name: "Global Testing Lab",
		logo: "/api/fpdf/assets/globaltestinglab/gtl_logo.png",
		color: "#29BFB6",
		url: "https://www.globaltestinglab.com/gb/contact-us/",
		email: "info@globaltestinglab.com",
		address1: "",
		address2: "",
		address3: "",
		address4: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		tel: ""
	},
	"GTL-AU-": {
		prefix: "GTL-AU",
		brandPrefix: "GTL",
		countryPrefix: "AU",
		name: "Global Testing Lab",
		logo: "/api/fpdf/assets/globaltestinglab/gtl_logo.png",
		color: "#29BFB6",
		url: "https://www.globaltestinglab.com/au/",
		email: "info@globaltestinglab.com",
		address1: "",
		address2: "",
		address3: "",
		address4: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		tel: ""
	},
	"GTL-CA-": {
		prefix: "GTL-CA",
		brandPrefix: "GTL",
		countryPrefix: "CA",
		name: "Global Testing Lab",
		logo: "/api/fpdf/assets/globaltestinglab/gtl_logo.png",
		color: "#29BFB6",
		url: "https://www.globaltestinglab.com/ca/",
		email: "info@globaltestinglab.com",
		address1: "",
		address2: "",
		address3: "",
		address4: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		tel: ""
	},
	"GTL-IE-": {
		prefix: "GTL-IE",
		brandPrefix: "GTL",
		countryPrefix: "IE",
		name: "Global Testing Lab",
		logo: "/api/fpdf/assets/globaltestinglab/gtl_logo.png",
		color: "#29BFB6",
		url: "https://www.globaltestinglab.com/ie/",
		email: "info@globaltestinglab.com",
		address1: "",
		address2: "",
		address3: "",
		address4: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		tel: ""
	},
	"GTL-US-": {
		prefix: "GTL-US",
		brandPrefix: "GTL",
		countryPrefix: "US",
		name: "Global Testing Lab",
		logo: "/api/fpdf/assets/globaltestinglab/gtl_logo.png",
		color: "#29BFB6",
		url: "https://www.globaltestinglab.com/us/contact-us/",
		email: "info@globaltestinglab.com",
		address1: "",
		address2: "",
		address3: "",
		address4: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		tel: ""
	},
	"HS-": {
		prefix: "HS",
		brandPrefix: "HS",
		countryPrefix: "",
		name: "Healthy Stuff",
		logo: "/api/fpdf/assets/healthystuff/hs_logo.png",
		color: "#578F57",
		url: "https://www.healthystuff.com/contact",
		email: "info@healthystuff.com",
		address1: "1A Bonington Complex",
		address2: "Trent Lane Industrial Estate",
		address3: "",
		address4: "",
		city: "Derby",
		state: "",
		postcode: "DE74 2PY",
		country: "United Kingdom",
		tel: "01332 850085"
	},
	"HTL-": {
		prefix: "HTL",
		brandPrefix: "HTL",
		countryPrefix: "",
		name: "Home To Lab",
		logo: "",
		color: "",
		url: "https://www.hometolab.com/contact",
		email: "",
		address1: "1a Bonington Complex",
		address2: "Trent Lane",
		address3: "",
		address4: "",
		city: "Derby",
		state: "",
		postcode: "DE74 2PY",
		country: "",
		tel: ""
	},
	"RTYI-": {
		prefix: "RTYI",
		brandPrefix: "RTYI",
		countryPrefix: "",
		name: "Test Your Intolerance",
		logo: "/api/fpdf/assets/testyourintolarance/tyi_logo.png",
		color: "#578F57",
		url: "https://www.testyourintolerance.ca/contact-us/",
		email: "info@testyourintolerance.com",
		address1: "",
		address2: "",
		address3: "",
		address4: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		tel: "1 832 369-0544"
	},
	"SC-": {
		prefix: "SC",
		brandPrefix: "SC",
		countryPrefix: "",
		name: "Sensitivity Check",
		logo: "/api/fpdf/assets/sensitivitycheck/sc_logo.png",
		color: "#0C7088",
		url: "https://www.sensitivitycheck.com/contact",
		email: "",
		address1: "1A Bonington Complex",
		address2: "Trent Lane Industrial Estate",
		address3: "",
		address4: "",
		city: "Derby",
		state: "",
		postcode: "DE74 2PY",
		country: "United Kingdom",
		tel: "01332 850085"
	},
	"SC-AU-": {
		prefix: "SC-AU",
		brandPrefix: "SC",
		countryPrefix: "AU",
		name: "Sensitivity Check",
		logo: "/api/fpdf/assets/sensitivitycheck/sc_logo.png",
		color: "#0C7088",
		url: "https://www.sensitivitycheckaustralia.com/contact/",
		email: "",
		address1: "PO Box 491",
		address2: "",
		address3: "",
		address4: "",
		city: "Kellyville",
		state: "New South Wales",
		postcode: "2155",
		country: "Australia",
		tel: "61 1800 196 618"
	},
	"SC-CA-": {
		prefix: "SC-CA",
		brandPrefix: "SC",
		countryPrefix: "CA",
		name: "Sensitivity Check",
		logo: "/api/fpdf/assets/sensitivitycheck/sc_logo.png",
		color: "#0C7088",
		url: "https://www.sensitivitycheckcanada.com/contact/",
		email: "",
		address1: "Unit 106",
		address2: "482 South Service Road East",
		address3: "",
		address4: "",
		city: "Oakville",
		state: "Ontario",
		postcode: "L6J 2X6",
		country: "Canada",
		tel: ""
	},
	"SC-IE-": {
		prefix: "SC-IE",
		brandPrefix: "SC",
		countryPrefix: "IE",
		name: "Sensitivity Check",
		logo: "/api/fpdf/assets/sensitivitycheck/sc_logo.png",
		color: "#0C7088",
		url: "https://www.sensitivitycheckireland.com/contact/",
		email: "",
		address1: "Bracken Road No 51",
		address2: "Carlisle Offices",
		address3: "",
		address4: "",
		city: "Sandyford",
		state: "Dublin",
		postcode: "D18 CV48",
		country: "Ireland",
		tel: "1800 816 057"
	},
	"SC-NZ-": {
		prefix: "SC-NZ",
		brandPrefix: "SC",
		countryPrefix: "NZ",
		name: "Sensitivity Check",
		logo: "/api/fpdf/assets/sensitivitycheck/sc_logo.png",
		color: "#0C7088",
		url: "https://www.sensitivitychecknewzealand.com/contact/",
		email: "",
		address1: "Suite 8091",
		address2: "PO Box 13240",
		address3: "",
		address4: "",
		city: "Johnsonville",
		state: "Wellington",
		postcode: "6440",
		country: "New Zealand",
		tel: ""
	},
	"SC-US-": {
		prefix: "SC-US",
		brandPrefix: "SC",
		countryPrefix: "US",
		name: "Sensitivity Check",
		logo: "/api/fpdf/assets/sensitivitycheck/sc_logo.png",
		color: "#0C7088",
		url: "https://www.sensitivitycheckus.com/contact/",
		email: "",
		address1: "870 N. Miramar Avenue",
		address2: "",
		address3: "",
		address4: "",
		city: "Indialantic",
		state: "Florida",
		postcode: "32903",
		country: "United States",
		tel: "1 832 369-0544"
	},
	"SC-FR-": {
		prefix: "SC-FR",
		brandPrefix: "SC",
		countryPrefix: "FR",
		name: "Sensitivity Check",
		logo: "/api/fpdf/assets/sensitivitycheck/sc_logo.png",
		color: "#0C7088",
		url: "https://www.sensitivitycheckfrance.com/contact/",
		email: "",
		address1: "",
		address2: "",
		address3: "",
		address4: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		tel: ""
	},
	"SC-NL-": {
		prefix: "SC-NL",
		brandPrefix: "SC",
		countryPrefix: "NL",
		name: "Sensitivity Check",
		logo: "/api/fpdf/assets/sensitivitycheck/sc_logo.png",
		color: "#0C7088",
		url: "https://www.sensitivitychecknetherlands.com/contact/",
		email: "",
		address1: "",
		address2: "",
		address3: "",
		address4: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		tel: ""
	},
	"SC-CH-": {
		prefix: "SC-CH",
		brandPrefix: "SC",
		countryPrefix: "CH",
		name: "Sensitivity Check",
		logo: "/api/fpdf/assets/sensitivitycheck/sc_logo.png",
		color: "#0C7088",
		url: "https://www.sensitivitycheckswitzerland.com/contact/",
		email: "",
		address1: "",
		address2: "",
		address3: "",
		address4: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		tel: ""
	},
	"TMA-": {
		prefix: "TMA",
		brandPrefix: "TMA",
		countryPrefix: "",
		name: "Test My Allergy",
		logo: "/api/fpdf/assets/tma/tma_logo.png",
		color: "#EA1B62",
		url: "https://www.testmyallergy.com/contact-page/",
		email: "info@testmyallergy.com",
		address1: "",
		address2: "",
		address3: "",
		address4: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		tel: ""
	},
	"TMFS-": {
		prefix: "TMFS",
		brandPrefix: "TMFS",
		countryPrefix: "",
		name: "Test My Food Sensitivity",
		logo: "/api/fpdf/assets/tmfs/tmfs_logo.png",
		color: "#EA1B62",
		url: "https://testmyfoodsensitivity.com/faqs/",
		email: "",
		address1: "870 N. Miramar Avenue",
		address2: "",
		address3: "",
		address4: "",
		city: "Indialantic",
		state: "Florida",
		postcode: "32903",
		country: "United States",
		tel: "1 832 369-0544"
	},
	"TMFS-UK-": {
		prefix: "TMFS-UK",
		brandPrefix: "TMFS",
		countryPrefix: "UK",
		name: "Test My Food Sensitivity",
		logo: "/api/fpdf/assets/tmfs/tmfs_logo.png",
		color: "#EA1B62",
		url: "https://testmyfoodsensitivity.co.uk/faqs/",
		email: "",
		address1: "",
		address2: "",
		address3: "",
		address4: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		tel: ""
	},
	"TMP-AU-": {
		prefix: "TMP-AU",
		brandPrefix: "TMP",
		countryPrefix: "AU",
		name: "Test My Pets",
		logo: "",
		color: "",
		url: "https://testmypetsaustralia.com/contact/",
		email: "",
		address1: "PO Box 491",
		address2: "",
		address3: "",
		address4: "",
		city: "Kellyville",
		state: "",
		postcode: "NSW 2155",
		country: "",
		tel: ""
	},
	"TMP-NZ-": {
		prefix: "TMP-NZ",
		brandPrefix: "TMP",
		countryPrefix: "NZ",
		name: "Test My Pets",
		logo: "",
		color: "",
		url: "https://testmypetsnz.com/contact/",
		email: "",
		address1: "Healthy Stuff",
		address2: "Suite 8091",
		address3: "PO Box 13240 Johnsonville",
		address4: "",
		city: "Wellington",
		state: "",
		postcode: "6440",
		country: "",
		tel: ""
	},
	"TYI-US-": {
		prefix: "TYI-US",
		brandPrefix: "TYI",
		countryPrefix: "US",
		name: "Test Your Intolerance",
		logo: "/api/fpdf/assets/tyi/MicrosoftTeams-image (6).png",
		color: "#EA1B62",
		url: "https://testyourintolerance.co",
		email: "",
		address1: "",
		address2: "",
		address3: "",
		address4: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		tel: ""
	},
	"TYI-": {
		prefix: "TYI",
		brandPrefix: "TYI",
		countryPrefix: "",
		name: "Test Your Intolerance",
		logo: "/api/fpdf/assets/tyi/MicrosoftTeams-image (6).png",
		color: "#0C7088",
		url: "https://www.testyourintolerance.com/contact-us/",
		email: "info@testyourintolerance.com",
		address1: "1A Bonington Complex",
		address2: "Trent Lane Industrial Estate",
		address3: "",
		address4: "",
		city: "Derby",
		state: "",
		postcode: "DE74 2PY",
		country: "United Kingdom",
		tel: "01332 850085"
	},
	"TYI-CA-": {
		prefix: "TYI-CA",
		brandPrefix: "TYI",
		countryPrefix: "CA",
		name: "Test Your Intolerance",
		logo: "/api/fpdf/assets/tyi/MicrosoftTeams-image (6).png",
		color: "#0C7088",
		url: "https://www.testyourintolerance.ca/contact-us/",
		email: "info@testyourintolerance.com",
		address1: "Unit 106",
		address2: "482 South Service Road East",
		address3: "",
		address4: "",
		city: "Oakville",
		state: "Ontario",
		postcode: "L6J 2X6",
		country: "Canada",
		tel: ""
	},
	"TYI-NZ-": {
		prefix: "TYI-NZ",
		brandPrefix: "TYI",
		countryPrefix: "NZ",
		name: "Test Your Intolerance",
		logo: "/api/fpdf/assets/tyi/MicrosoftTeams-image (6).png",
		color: "#0C7088",
		url: "https://www.testyourintolerance.co.nz/contact-us/",
		email: "info@testyourintolerance.com",
		address1: "Suite 8091",
		address2: "PO Box 13240",
		address3: "",
		address4: "",
		city: "Johnsonville",
		state: "Wellington",
		postcode: "6440",
		country: "New Zealand",
		tel: ""
	},
};

export default Brands;
