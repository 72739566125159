/**
 * 
 * Home component
 * This component showcases a grid which routes visitors to different tests
 * The design is based off https://checkmybodyhealth.com/activate/
 * 
 * @returns Home : PureComponent
 */

 import "./HomePage.scss";
 import { NavLink } from "react-router-dom";
 import { BsFillDropletFill } from "react-icons/bs";
 import hair_strands from "../assets/hair_strands.png";
 
 const HomePage = () => {
 
     return(
             <section className="home-page">
             
             <h1 className="home-page__header">Please choose your test</h1>
 
                 <ul className="home-page__tests-grid">
 
                    <li className="home-page__test">
                    <NavLink to="/blood-submissions" className="blood-hair-test-link">
                        <BsFillDropletFill className="blood-icon" />
                        <p style={{ color: "white" }}>Blood Test</p>
                    </NavLink>
                </li>
                 
                 <li className="home-page__test">
                     <NavLink to="/hair-submissions" className="blood-hair-test-link">
                         <img
                             src={hair_strands}
                             alt="Hair strands logo"
                         />
                         <p style={{ color: "white" }}>Hair Test</p>
                     </NavLink>
                 </li>                       
 
             </ul>
             <br /> <br />
             <p className="home-page__instructions"><b style={{ fontSize: "1.4rem" }}>Not sure how to take your sample?</b></p>
             <br />
             <b style={{ color: "#126086", fontSize: "1.4rem" }}>How to use a...</b>
  
             <ul className="home-page__tests-grid">
 
             <li className="home-page__test" style={{ color: "#ed0c6e" }}>
                  <NavLink to="/blood" className="instructions-blood-hair-test-link">
                        <p>Blood Kit</p>
                    </NavLink>
                  </li>
 
                 
             <li className="home-page__test" style={{ color: "#ed0c6e" }}>
                 <NavLink to="/hair" className="instructions-blood-hair-test-link">
                        <p>Hair Kit</p>
                    </NavLink>
                 </li>
             </ul>   
             <br />
             <p className="home-page__instructions"><b style={{ fontSize: "1.4rem" }}>Want to know more?</b></p>
 
             <div className="home-page__embedded-video">
                 <iframe 
                     src="https://www.youtube.com/embed/p6box9jzlDw" 
                     title="Activatemytest.com | How it Works" 
                     frameBorder="0" 
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                     allowFullScreen>
                 </iframe>
             </div>
 
         </section>
     );
 };
 
 export default HomePage;
 