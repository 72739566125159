/**
 * Hair Submission Form component.
 * Takes form data and validates it.
 * If successful, an api request is sent to './api/Controllers/Hair/Submission.php'.
 * Redirects to submission thank you page on a successful submission.
 * The postcode checker checks for regional postcodes using the postcode validator package.
 * 
 * @returns HairSubmissionForm : PureComponent 
 */

import "./HairSubmissionForm.scss";
import { FormEvent, useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import Checkbox from "../../inputs/Checkbox";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Brands from "../../../consts/Brands";
import Postcodes from "../../../consts/Postcodes";
import { useSearchParams } from 'react-router-dom';

const endpoint = "/api/controllers/Hair/Submission.php";
const reTestEndpoint = "/api/controllers/Hair/Submission.php";

const HairSubmissionForm = () => {

	const submissionCode = useParams().code;

	const [searchParams] = useSearchParams();
	const product = searchParams.get('product') ?? "";

	const navigate = useNavigate();

	if (postcodeValidatorExistsForCountry("GB") === false) {
		throw new Error("Warning, postcode validator doesn't work. Please check the package");
	}

	// Input refs
	const firstNameRef = useRef<HTMLInputElement>(null);
	const lastNameRef = useRef<HTMLInputElement>(null);
	const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
	const genderRef = useRef<HTMLSelectElement>(null);
	const postcodeRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);
	const secondEmailRef = useRef<HTMLInputElement>(null);
	const reCaptchaRef = useRef<ReCAPTCHA>(null);
	const [brand, setBrand] = useState<any>((Brands as any)["default"]);
	const [reTest, setReTest] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	// Form validation states
	const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
	const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);
	const [isDateOfBirthValid, setIsDateOfBirthValid] = useState<boolean>(true);
	const [isPostcodeValid, setIsPostcodeValid] = useState<boolean>(true);
	const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
	const [isSecondEmailValid, setIsSecondEmailValid] = useState<boolean>(true);
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [consentChecked1, setConsentChecked1] = useState<boolean>(false);
	const [isReCaptchaValid, setIsReCaptchaValid] = useState<boolean>(false);
	const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
	const [formSuccess, setFormSuccess] = useState<boolean>(false);
	const [showPopup, setShowPopup] = useState<boolean>(false);

	useEffect(() => {
		const validateSubmissionCode = () => {

			if (submissionCode) {
				let orderNumber = "";

				let code = submissionCode;
				if (code.lastIndexOf("-R") === code.length - 2) {
					code = code.substring(0, code.length - 2);
					setReTest(true);
				} else {
					setReTest(false);
				}

				Object.keys(Brands).sort().forEach((key) => {
					if (code.indexOf(key) === 0) {
						orderNumber = code.substring(key.length);
						setBrand((Brands as any)[key]);
					}
				});

				if (orderNumber !== "") {
					return /^[0-9]+-[0-9]+$/.test(orderNumber);
				}
			}

			return false;
		};

		if (!validateSubmissionCode()) {
			navigate("/hair/submission/");
		}

	}, [submissionCode, navigate]);

	// Validate regional postcode
	const validateRegionalPostcode = (postcode: string) => {

		let postcodeIsValid = false;

		// Loop through the array of postcodes to match the postcode
		// If true, we return true at the end of this function, otherwise, it stays false
		// eslint-disable-next-line array-callback-return
		Postcodes.map((code: string) => {

			if (postcodeValidator(postcode, code) === true) {
				postcodeIsValid = true;
			}
		});

		return postcodeIsValid;
	};

	// Toggle checkbox
	const handleCheckboxToggle1 = () => {
		setConsentChecked1(!consentChecked1);
	};

	// Validate our email address as being valid by checking it against regular expression
	const validateEmail = (email: string) => {
		return /^[^@]+@[^@]+\.[^@]+$/.test(email);
	};

	// Make sure the input is not empty
	const validateInput = (input: string) => {
		return input.length > 0;
	};

	const submitHandler = (event: FormEvent) => {

		event.preventDefault();

		// We set this to true for our recaptcha
		setFormSubmitted(true);

		let firstNameValid, lastNameValid, dateOfBirthValid, postcodeValid, emailValid, confirmEmailValid, tokenValid: boolean = false;

		//  Validate inputs on the front end
		if (firstNameRef.current) {
			firstNameValid = validateInput(firstNameRef.current.value);
			setIsFirstNameValid(firstNameValid);
		}

		if (lastNameRef.current) {
			lastNameValid = validateInput(lastNameRef.current.value);
			setIsLastNameValid(lastNameValid);
		}

		dateOfBirthValid = dateOfBirth ? true : false;
		setIsDateOfBirthValid(dateOfBirthValid);

		if (postcodeRef.current) {
			postcodeValid = validateRegionalPostcode(postcodeRef.current.value)
			setIsPostcodeValid(postcodeValid);
		}

		if (emailRef.current) {
			emailValid = validateEmail(emailRef.current.value);
			setIsEmailValid(emailValid);
		}

		if (emailRef.current && secondEmailRef.current) {
			confirmEmailValid = (emailRef.current.value === secondEmailRef.current.value);
			setIsSecondEmailValid(confirmEmailValid);
		}

		if (reCaptchaRef.current) {
			const token = reCaptchaRef.current.getValue();

			// If token isn't null ( which it won't be )
			if (token) {
				tokenValid = validateInput(token);
				setIsReCaptchaValid(tokenValid);
			} else {
				setIsReCaptchaValid(false);
			}

		}

		// If our inputs are valid, submit the form
		if (firstNameValid && lastNameValid && dateOfBirthValid && postcodeValid && emailValid && confirmEmailValid && tokenValid) {

			// If we haven't successfully submitted an activation form, submit one. We do this to prevent spamming the submit button
			if (formSuccess === false) {
				saveToDatabase();
			}
		}

	}

	// Query the backend in so we can send a token to LIMS
	const saveToDatabase = async () => {

		// Format date into YYYY-MM-DD
		let formattedDate: string = dateOfBirth?.toISOString().slice(0, 10) ?? "";

		// Get recaptcha value
		let captchaToken;
		let token: string = "";

		if (reCaptchaRef.current) {
			captchaToken = reCaptchaRef.current.getValue();

			if (captchaToken !== null) {
				token = captchaToken;
			}
		}

		// Submit our form to the backend of LIMS using an api request, if it's successful, we'll toggle a pop up to let the customer know that they were successful
		const fields = new FormData();
		submissionCode && fields.append("submissionCode", submissionCode);
		product && fields.append("product", product);
		firstNameRef.current && fields.append("firstName", firstNameRef.current.value);
		lastNameRef.current && fields.append("lastName", lastNameRef.current.value);
		formattedDate && fields.append("dateOfBirth", formattedDate);
		genderRef.current && fields.append("gender", genderRef.current.value);
		postcodeRef.current && fields.append("postcode", postcodeRef.current.value);
		emailRef.current && fields.append("email", emailRef.current.value);
		fields.append("phoneNumber", phoneNumber ? phoneNumber : "");
		consentChecked1 && fields.append("marketingEmailList", "1");
		reCaptchaRef.current && fields.append("recaptchaToken", token);

		try {
			setFormSuccess(true);
			setShowPopup(false);

			// Perform API request
			// Note: Do not pass a content type through to this request as the browser completes for you as well as your boundary
			const response = await fetch(reTest? reTestEndpoint : endpoint, {
				method: "POST",
				body: fields
			});

			const data = await response.json();

			setFormSuccess(data.success);

			if (data.issue === "recaptcha") {
				setIsReCaptchaValid(false);
			}

			if (data.success === true) {
				navigate(`/hair/thank-you/${submissionCode}`);
			} else {
				setErrorMessage(data.message ? data.message : "");
				setShowPopup(true);
			}
		} catch (ex: any) {
			setFormSuccess(false);
			setErrorMessage(ex.message ? ex.message : "");
			setShowPopup(true);
			throw ex;
		}
	};

	return (
		<section className="hair-form-wrapper">

			<img
				src={brand.logo}
				alt={brand.name + " Logo"}
				className="app__logo"
			/>

			{showPopup &&
				<p className="hair-form-popup hair-form-popup--error">
					Form Submission Unsuccessful
					<br />
					{errorMessage ? errorMessage : ""}
					{errorMessage && <br />}
					Please fix the errors found below
					<br />
					Then Resubmit the Form
				</p>
			}

			<form className="hair-form" onSubmit={submitHandler}>

				<p className="hair-form__title">Please complete the Submission form.</p>
				<p className="hair-form__title">Fill out one form per test.</p>

				<div className="hair-form__field">
					<label id="submission_code_label" htmlFor="submission_code" className="hair-form__label">Submission Code</label>
					<input
						type="text"
						name="submission_code"
						aria-labelledby="submission_code_label"
						aria-required
						className="hair-form__input"
						readOnly
						value={submissionCode}
					/>
					<input
						type="hidden"
						name="product"
						value={product}
					/>
				</div>

				<div className={`hair-form__field ${!isFirstNameValid && "hair-form__field--error"}`}>
					<label id="first_name_label" htmlFor="first_name" className="hair-form__label">{isFirstNameValid ? "First Name*" : "Error: First name is empty"}</label>
					<input
						type="text"
						name="first_name"
						aria-labelledby="first_name_label"
						placeholder="Please enter your first name..."
						aria-required
						className={`hair-form__input ${!isFirstNameValid && "hair-form__input--error"}`}
						ref={firstNameRef}
					/>
				</div>

				<div className={`hair-form__field ${!isLastNameValid && "hair-form__field--error"}`}>
					<label id="last_name_label" htmlFor="last_name" className="hair-form__label">{isLastNameValid ? "Last Name*" : "Error: Last name is empty"}</label>
					<input
						type="text"
						name="last_name"
						aria-labelledby="last_name_label"
						placeholder="Please enter your last name..."
						aria-required
						className={`hair-form__input ${!isLastNameValid && "hair-form__input--error"}`}
						ref={lastNameRef}
					/>
				</div>

				<div className={`hair-form__field ${!isDateOfBirthValid && "hair-form__field--error"}`}>
					<label id="date_of_birth_label" htmlFor="date_of_birth" className="hair-form__label">Date Of Birth*</label>
					<DatePicker
						name="date_of_birth"
						aria-labelledby="date_of_birth_label"
						className={`hair-form__field ${!isDateOfBirthValid && "hair-form__field--error"}`}
						selected={dateOfBirth}
						onChange={(date) => setDateOfBirth(date)}
						showYearDropdown={true}
						dateFormat="dd/MM/yyyy"
						placeholderText="Please enter your date of birth..."
						aria-required
					/>
				</div>

				<div className="hair-form__field">
					<label id="gender_label" htmlFor="gender" className="hair-form__label">Gender</label>
					<select
						name="gender"
						aria-labelledby="gender_label"
						ref={genderRef}
						defaultValue="default"
						className="hair-form__dropdown"
						style={{
							background: "url('https://lims.healthystuff.com/arrow-down.svg')",
							backgroundSize: "18px",
							backgroundRepeat: "no-repeat",
							backgroundPositionY: "50%",
							backgroundPositionX: "calc(100% - 5px)"
						}}
					>
						<option key="default" value={"default"} disabled>Choose Gender</option>
						<option key="male" value={"Male"}>Male</option>
						<option key="female" value={"Female"}>Female</option>
						<option key="other" value={"Other"}>Other</option>
						<option key="neither" value={"Prefer not to say"}>Prefer not to say</option>
					</select>
				</div>

				<div className={`hair-form__field ${!isPostcodeValid && "hair-form__field--error"}`}>
					<label id="postcode_label" htmlFor="postcode" className="hair-form__label">{isPostcodeValid ? "Zipcode/Postcode*" : "Error: Zipcode/Postcode isn't valid"}</label>
					<input
						type="text"
						name="postcode"
						aria-labelledby="postcode_label"
						aria-required
						placeholder="Please enter your postcode..."
						className={`hair-form__input ${!isPostcodeValid && "hair-form__input--error"}`}
						ref={postcodeRef}
					/>
				</div>

				<div className={`hair-form__field ${!isEmailValid && "hair-form__field--error"}`}>
					<label id="email_address_label" htmlFor="email_address" className="hair-form__label">{isEmailValid ? "Email Address*" : "Error: Email address isn't valid"}</label>
					<input
						type="email"
						name="email_address"
						aria-labelledby="email_address_label"
						aria-required
						placeholder="Please enter your email address..."
						className={`hair-form__input ${!isEmailValid && "hair-form__input--error"}`}
						ref={emailRef}
					/>
				</div>

				<div className={`hair-form__field ${!isSecondEmailValid && "hair-form__field--error"}`}>
					<label id="second_email_address_label" htmlFor="second_email_address" className="hair-form__label">{isSecondEmailValid ? "Confirm Your Email Address*" : "Error: email addresses don't match"}</label>
					<input
						type="email"
						name="second_email_address"
						aria-labelledby="second_email_address_label"
						aria-required
						placeholder="Please confirm your email address..."
						className={`hair-form__input ${!isSecondEmailValid && "hair-form__input--error"}`}
						ref={secondEmailRef}
					/>
				</div>

				<PhoneInput
					country={'gb'}
					value={phoneNumber?.toString()}
					onChange={setPhoneNumber}
				/>

				<Checkbox
					isChecked={consentChecked1}
					label="I consent to receiving personalised recommendations for health-improving products in your range."
					handleChange={handleCheckboxToggle1}
				/>

				{
					formSubmitted && (isReCaptchaValid === false) &&
					<p className="hair-form__recaptcha-error">Error: ReCaptcha is invalid</p>
				}
				<ReCAPTCHA
					sitekey="6Ld_2pskAAAAAPFePhfrk2K7T3mVksszVs9XF16X"
					ref={reCaptchaRef}
				/>

				<div className="checkbox" style={{textAlign: "left"}} >
					By submitting your details, you consent to the processing of your sample, receipt of your results and any subsequent related communication pertaining to your results.
				</div>

				<button className="hair-form__submit">Submit</button>

			</form>

		</section>
	);
};

export default HairSubmissionForm;

