/**
 * 
 * Privacy Policy component
 * This component showcases a grid which routes visitors to different tests
 * The design is based off https://checkmybodyhealth.com/activate/
 * 
 * @returns PrivacyPolicyPage : PureComponent
 */

 import "./PrivacyPolicyPage.scss";
 import { NavLink } from "react-router-dom";
 import { BsArrowLeftSquareFill } from "react-icons/bs";
 
 const PrivacyPolicyPage = () => {
 
     return(
             <section className="home-page">
              <NavLink to="/" className="hair-form-link" style={{ color: "#FFAE42" }}><BsArrowLeftSquareFill/>Go Back</NavLink>
             
             <h1 className="privacy-page__header">Privacy Policy</h1>
             <h2 className="privacy-page__header"><b style={{ fontSize: "1.4rem" }}>Introduction</b></h2>
             <p className="privacy-page__copy">
               Welcome to Healthy Stuff Online Limited’s privacy notice
             </p>
             <p className="privacy-page__copy">
               We respect your privacy and are committed to protecting your personal data.
               This privacy notice will inform you as to how we look after your personal data
               when you get in touch with us, we contact you or when you visit our site
               https://activatemytest.com/ (our site) (regardless of where you contact us or
               visit our site from) and tell you about your privacy rights and how the law
               protects you.
             </p>
             <p className="privacy-page__copy">
               This privacy notice is provided in a layered format so you can click through
               to the specific areas set out below. Please also use the Glossary to
               understand the meaning of some of the terms used in this privacy notice.
             </p>
             <p className="privacy-page__copy">1. Important information and who we are</p>
             <p className="privacy-page__copy">2. The data we collect about you</p>
             <p className="privacy-page__copy">3. How is your personal data collected?</p>
             <p className="privacy-page__copy">4. Sample Handling and Data Privacy</p>
             <p className="privacy-page__copy">5. Disclosures of your personal data</p>
             <p className="privacy-page__copy">6. International transfers</p>
             <p className="privacy-page__copy">7. Data security</p>
             <p className="privacy-page__copy">8. Data retention</p>
             <p className="privacy-page__copy">9. Your legal rights</p>
             <p className="privacy-page__copy">10. Glossary</p>
             <h3 className="privacy-page__header">1. Content standards</h3>
             <p className="privacy-page__copy">
               <strong>Purpose of this privacy notice</strong>
             </p>
             <p className="privacy-page__copy">
               This privacy notice aims to give you information on how we collect and
               processes your personal data, including any data you may provide through our
               site when you sign up to our newsletter, purchase a product or service or take
               part in a competition.
             </p>
             <p className="privacy-page__copy">
               Our site is not intended for children and we do not knowingly collect data
               relating to children.
             </p>
             <p className="privacy-page__copy">
               It is important that you read this privacy notice together with any other
               privacy notice or fair processing notice we may provide on specific occasions
               when we are collecting or processing personal data about you so that you are
               fully aware of how and why we are using your data. This privacy notice
               supplements the other notices and is not intended to override them.
             </p>
             <p className="privacy-page__copy"><strong>Controller</strong></p>
             <p className="privacy-page__copy">
               We are the controller and responsible for your personal data.
             </p>
             <p className="privacy-page__copy">
               Healthy Stuff Online Limited is the controller and responsible for your
               personal data (referred to as “we”, “us” or “our” in this privacy notice).
             </p>
             <p className="privacy-page__copy">
               We have appointed a data protection officer (DPO) who is responsible for
               overseeing questions in relation to this privacy notice. If you have any
               questions about this privacy notice, including any requests to exercise your
               legal rights as set out in paragraph 9.1, please contact the DPO using the
               details set out below.
             </p>
             <p className="privacy-page__copy"><strong>Contact details.</strong></p>
             <p className="privacy-page__copy">
               If you have any questions about this privacy policy or our privacy practices,
               please contact our DPO in the following ways:
             </p>
             <ul className="privacy-page__copy">
               <li>Full name of legal entity: Healthy Stuff Online Limited</li>
               <li>Full name of DPO: Jason Wootton</li>
               <li>Email address: jason@healthystuff.com</li>
               <li>Postal address: Unit 1a, Bonington Complex, Trent Lane, DE74 2PY</li>
             </ul>
             <p className="privacy-page__copy">
               You have the right to make a complaint at any time to the Information
               Commissioner’s Office (ICO), the UK supervisory authority for data protection
               issues (www.ico.org.uk). We would, however, appreciate the chance to deal with
               your concerns before you approach the ICO so please contact us in the first
               instance.
             </p>
             <p className="privacy-page__copy">
               <strong>Your duty to inform us of changes.</strong>
             </p>
             <p className="privacy-page__copy">
               It is important that the personal data we hold about you is accurate and
               current. Please keep us informed if your personal data changes during your
               relationship with us.
             </p>
             <p className="privacy-page__copy"><strong>Third-party links.</strong></p>
             <p className="privacy-page__copy">
               Our site may include links to third-party websites, plug-ins and applications.
               Clicking on those links or enabling those connections may allow third parties
               to collect or share data about you. We do not control these third-party
               websites and are not responsible for their privacy statements. When you leave
               our site, we encourage you to read the privacy notice of every website you
               visit.
             </p>
             <h3 className="privacy-page__header">2. The data we collect about you</h3>
             <p className="privacy-page__copy">
               Personal data, or personal information, means any information about an
               individual from which that person can be identified. It does not include data
               where the identity has been removed (anonymous data).
             </p>
             <p className="privacy-page__copy">
               We may collect, use, store and transfer different kinds of personal data about
               you which we have grouped together follows:
             </p>
             <ul className="privacy-page__copy">
               <li>
                 Identity Data includes first name, maiden name, last name, username or
                 similar identifier, title, date of birth
               </li>
               <li>
                 Contact Data includes billing address, delivery address, email address and
                 telephone numbers.
               </li>
               <li>Financial Data includes bank account and payment card details.</li>
               <li>
                 Transaction Data includes details about payments to and from you and other
                 details of products and services you have purchased from us.
               </li>
               <li>
                 Technical Data includes [internet protocol (IP) address, your login data,
                 browser type and version, time zone setting and location, browser plug-in
                 types and versions, operating system and platform and other technology on
                 the devices you use to access our site].
               </li>
               <li>
                 Profile Data includes your username and password, and purchases or orders
                 made by you.
               </li>
               <li>
                 Marketing and Communications Data includes your preferences in receiving
                 marketing from us and our third parties and your communication preferences.
               </li>
             </ul>
             <p className="privacy-page__copy">
               We also collect, use and share Aggregated Data such as statistical or
               demographic data for any purpose. Aggregated Data may be derived from your
               personal data but is not considered personal data in law as this data does not
               directly or indirectly reveal your identity. For example, we may aggregate
               your Usage Data to calculate the percentage of users accessing a specific
               website feature. However, if we combine or connect Aggregated Data with your
               personal data so that it can directly or indirectly identify you, we treat the
               combined data as personal data which will be used in accordance with this
               privacy notice.
             </p>
             <p className="privacy-page__copy">
               We do not collect any Special Categories of Personal Data about you (this
               includes details about your race or ethnicity, religious or philosophical
               beliefs, sex life, sexual orientation, political opinions, trade union
               membership, information about your health and genetic and biometric data). Nor
               do we collect any information about criminal convictions and offences.
             </p>
             <p className="privacy-page__copy">
               <strong>If you fail to provide personal data</strong>
             </p>
             <p className="privacy-page__copy">
               Where we need to collect personal data by law, or under the terms of a
               contract we have with you and you fail to provide that data when requested, we
               may not be able to perform the contract we have or are trying to enter into
               with you (for example, to provide you with goods or services). In this case,
               we may have to cancel a product or service you have with us but we will notify
               you if this is the case at the time.
             </p>
             <h3 className="privacy-page__header">
               3. How is your personal data collected?
             </h3>
             <p className="privacy-page__copy">
               We use different methods to collect data from and about you including through:
             </p>
             <p className="privacy-page__copy">
               Direct interactions. You may give us your Identity, Contact and Financial Data
               by filling in forms or by corresponding with us by post, phone, email or
               otherwise. This includes personal data you provide when you:
             </p>
             <ul className="privacy-page__copy">
               <li>request our products or services;</li>
               <li>contact us by phone, through our site or by other means;</li>
               <li>create an account on our site;</li>
               <li>abandoned transactions;</li>
               <li>subscribe to our service or publications;</li>
               <li>request marketing to be sent to you;</li>
               <li>enter a competition, promotion or survey; or</li>
               <li>give us some feedback.</li>
             </ul>
             <p className="privacy-page__copy">
               Automated technologies or interactions. As you interact with our site, we may
               automatically collect Technical Data about your equipment, browsing actions
               and patterns. We collect this personal data by using cookies, server logs and
               other similar technologies. We may also receive Technical Data about you if
               you visit other websites employing our cookies.
             </p>
             <p className="privacy-page__copy">
               Third parties or publicly available sources. We may receive personal data
               about you from various third parties as set out below:
             </p>
             <ul className="privacy-page__copy">
               <li>
                 Technical Data from the following parties:
                 <ul>
                   <li>analytics providers such as Google based outside the EU;</li>
                   <li>advertising networks; and</li>
                   <li>search information providers.</li>
                 </ul>
               </li>
               <li>
                 Contact, Financial and Transaction Data from providers of technical, payment
                 and delivery services
               </li>
             </ul>
             <p className="privacy-page__copy">
               <strong>How we use your personal data</strong>
             </p>
             <p className="privacy-page__copy">
               We will only use your personal data when the law allows us to. Most commonly,
               we will use your personal data in the following circumstances:
             </p>
             <ul className="privacy-page__copy">
               <li>
                 Where we need to perform the contract we are about to enter into or have
                 entered into with you.
               </li>
               <li>
                 Where it is necessary for our legitimate interests (or those of a third
                 party) and your interests and fundamental rights do not override those
                 interests.
               </li>
               <li>Where we need to comply with a legal or regulatory obligation.</li>
             </ul>
             <p className="privacy-page__copy">
               Click here to find out more about the types of lawful basis that we will rely
               on to process your personal data.
             </p>
             <p className="privacy-page__copy">
               Generally, we do not rely on consent as a legal basis for processing your
               personal data other than in relation to sending third-party direct marketing
               communications to you via email or text message. You have the right to
               withdraw consent to marketing at any time by contacting us.
             </p>
             <p className="privacy-page__copy">
               <strong>Purposes for which we will use your personal data.</strong>
             </p>
             <p className="privacy-page__copy">
               We have set out below, in a table format, a description of all the ways we
               plan to use your personal data, and which of the legal bases we rely on to do
               so. We have also identified what our legitimate interests are where
               appropriate.
             </p>
             <p className="privacy-page__copy">
               Note that we may process your personal data for more than one lawful ground
               depending on the specific purpose for which we are using your data. Please
               contact us if you need details about the specific legal ground we are relying
               on to process your personal data where more than one ground has been set out
               in the table below.
             </p>
             <table className="privacy-page__copy">
               <tbody>
                 <tr>
                   <th>Purpose/Activity</th>
                   <th>Type of data</th>
                   <th>
                     Lawful basis for processing including basis of legitimate interest
                   </th>
                 </tr>
                 <tr>
                   <td>To register you as a new customer</td>
                   <td>(a) Identity<br />(b) Contact</td>
                   <td>Performance of a contract with you</td>
                 </tr>
                 <tr>
                   <td>
                     To process and deliver your order including:<br />(a) Manage payments,
                     fees and charges<br />(b) Collect and recover money owed to us
                   </td>
                   <td>
                     (a) Identity<br />(b) Contact<br />(c) Financial<br />(d) Transaction<br />(e)
                     Marketing and Communications
                   </td>
                   <td>
                     (a) Performance of a contract with you<br />(b) Necessary for our
                     legitimate interests (to recover debts due to us)
                   </td>
                 </tr>
                 <tr>
                   <td>
                     To manage our relationship with you which will include:<br />(a)
                     Notifying you about changes to our terms or privacy policy<br />(b)
                     Asking you to leave a review or take a survey
                   </td>
                   <td>
                     (a) Identity<br />(b) Contact<br />(c) Profile<br />(d) Marketing and
                     Communications
                   </td>
                   <td>
                     (a) Performance of a contract with you<br />(b) Necessary to comply with
                     a legal obligation<br />(c) Necessary for our legitimate interests (to
                     keep our records updated and to study how customers use our
                     products/services)
                   </td>
                 </tr>
                 <tr>
                   <td>
                     To enable you to partake in a prize draw, competition or complete a
                     survey
                   </td>
                   <td>
                     (a) Identity<br />(b) Contact<br />(c) Profile<br />(d) Usage<br />(e)
                     Marketing and Communications
                   </td>
                   <td>
                     (a) Performance of a contract with you<br />(b) Necessary for our
                     legitimate interests (to study how customers use our products/services,
                     to develop them and grow our business)
                   </td>
                 </tr>
                 <tr>
                   <td>
                     To administer and protect our business and our site (including
                     troubleshooting, data analysis, testing, system maintenance, support,
                     reporting and hosting of data)
                   </td>
                   <td>(a) Identity<br />(b) Contact<br />(c) Technical</td>
                   <td>
                     (a) Necessary for our legitimate interests (for running our business,
                     provision of administration and IT services, network security, to
                     prevent fraud and in the context of a business reorganisation or group
                     restructuring exercise)<br />(b) Necessary to comply with a legal
                     obligation
                   </td>
                 </tr>
                 <tr>
                   <td>
                     To deliver relevant website content and advertisements to you and
                     measure or understand the effectiveness of the advertising we serve to
                     you
                   </td>
                   <td>
                     (a) Identity<br />(b) Contact<br />(c) Profile<br />(d) Usage<br />(e)
                     Marketing and Communications<br />(f) Technical
                   </td>
                   <td>
                     Necessary for our legitimate interests (to study how customers use our
                     products/services, to develop them, to grow our business and to inform
                     our marketing strategy)
                   </td>
                 </tr>
                 <tr>
                   <td>
                     To use data analytics to improve our site, products/services, marketing,
                     customer relationships and experiences
                   </td>
                   <td>(a) Technical<br />(b) Usage</td>
                   <td>
                     Necessary for our legitimate interests (to define types of customers for
                     our products and services, to keep our site updated and relevant, to
                     develop our business and to inform our marketing strategy)
                   </td>
                 </tr>
                 <tr>
                   <td>
                     To make suggestions and recommendations to you about goods or services
                     that may be of interest to you
                   </td>
                   <td>
                     (a) Identity<br />(b) Contact<br />(c) Technical<br />(d) Usage<br />(e)
                     Profile
                   </td>
                   <td>
                     Necessary for our legitimate interests (to develop our products/services
                     and grow our business)
                   </td>
                 </tr>
               </tbody>
             </table>
             <p className="privacy-page__copy"><strong>Third-party review sites</strong></p>
             <p className="privacy-page__copy">
               We may pass your contact information and limited purchase history to either of
               our nominated review partners (TrustedShops or TrustPilot) in order to collect
               feedback from you following your purchase on our website. This information is
               only kept within our secure designated partner account and cannot be accessed
               or used by either the review partner or any other third party for any other
               purpose than to provide feedback on your experience with us.
             </p>
             <p className="privacy-page__copy"><strong>Abandoned purchases</strong></p>
             <p className="privacy-page__copy">
               In the event that you initiate a purchase through our website and do not
               complete the full checkout process, we will send you a one-off email advising
               of the incomplete status of your purchase. You will only receive one of these
               emails and your data will not be passed to other campaign databases or
               third-parties.
             </p>
             <p className="privacy-page__copy">
               <strong>Promotional offers from us</strong>
             </p>
             <p className="privacy-page__copy">
               We may use your Identity, Contact, Technical, Usage and Profile Data to form a
               view on what we think you may want or need, or what may be of interest to you.
               This is how we decide which products, services and offers may be relevant for
               you (we call this marketing).
             </p>
             <p className="privacy-page__copy">
               You will receive marketing communications from us if you have requested
               information from us or purchased goods or services from us or if you provided
               us with your details when you entered a competition or registered for a
               promotion and, in each case, you have not opted out of receiving that
               marketing.
             </p>
             <p className="privacy-page__copy">
               <strong
                 >E-mail advertising if you do not subscribe to the newsletter and your right
                 to opt out.
               </strong>
             </p>
             <p className="privacy-page__copy">
               If we receive your e-mail address in connection with the sale of a product or
               service and you have not opted out and you are not a consumer who has his
               habitual residence in Poland, we reserve the right to regularly email you
               offers for products from our product range that are similar to those you have
               already purchased. This serves the protection of our legitimate interests in
               promoting and advertising our products to customers that are overriding in the
               process of balancing of interests. You can opt out of this use of your email
               address at any time by sending a message to the contact option specified below
               or by using the opt-out link in the advertising email, without incurring any
               costs beyond the cost of transfer calculated at the base rates.
             </p>
              <p className="privacy-page__copy"><strong>Third-party marketing</strong></p>
              <p className="privacy-page__copy">
                We will get your express opt-in consent before we share your personal data
                with any third-party company outside the Healthy Stuff Online Limited group of
                companies for marketing purposes.
              </p>
              <p className="privacy-page__copy"><strong>E-mail advertising if you subscribe to the newsletter </strong></p>
              <p className="privacy-page__copy">
                If you subscribe to our newsletter, we will regularly send you our e-mail
                newsletter based on your consent according to Art. 6 (1) 1 lit. a) GDPR, using
                the data required or disclosed by you separately for this purpose.
              </p>
              <p className="privacy-page__copy">
                You may unsubscribe from the newsletter service at any time. For this purpose,
                you can either send a message to the contact option specified below or use the
                opt-out link in the newsletter. Upon unsubscription, we will delete your email
                address unless you have expressly consented to the further use of your data or
                we reserve the right to further use your personal data in the scope and manner
                permitted by the law, of which we inform you in this notice.
              </p>
              <p className="privacy-page__copy"><strong>SMS Text Messaging</strong></p>
              <p className="privacy-page__copy">
                We may use the personal information we collect to communicate with individuals
                via text message, including to market to you, offer you information and
                updates on products or services we think you may be interested in and send
                cart/checkout reminders. We will only do this if we have received your consent
                to market in this way. Message &amp; data rates may apply. Message frequency
                varies. You can unsubscribe from marketing text messages at any time by
                replying STOP or clicking the unsubscribe link (where available) in one of our
                messages.
              </p>
              <p className="privacy-page__copy"><strong>Opting out</strong></p>
              <p className="privacy-page__copy">
                You can ask us or third parties to stop sending you marketing messages at any
                time by contacting us at any time.
              </p>
              <p className="privacy-page__copy">
                Where you opt out of receiving these marketing messages, this will not apply
                to personal data provided to us as a result of a product/service purchase.
              </p>
              <p className="privacy-page__copy"><strong>Cookies</strong></p>
              <p className="privacy-page__copy">
                You can set your browser to refuse all or some browser cookies, or to alert
                you when websites set or access cookies. If you disable or refuse cookies,
                please note that some parts of our site may become inaccessible or not
                function properly.
              </p>
              <p className="privacy-page__copy"><strong>Change of purpose</strong></p>
              <p className="privacy-page__copy">
                We will only use your personal data for the purposes for which we collected
                it, unless we reasonably consider that we need to use it for another reason
                and that reason is compatible with the original purpose. If you wish to get an
                explanation as to how the processing for the new purpose is compatible with
                the original purpose, please contact us.
              </p>
              <p className="privacy-page__copy">
                If we need to use your personal data for an unrelated purpose, we will notify
                you and we will explain the legal basis which allows us to do so.
              </p>
              <p className="privacy-page__copy">
                Please note that we may process your personal data without your knowledge or
                consent, in compliance with the above rules, where this is required or
                permitted by law.
              </p>
              <h3 className="privacy-page__header">4. Sample Handling and Data Privacy</h3>
              <p className="privacy-page__copy">
                When you submit a sample for testing, it is handled with the utmost care and
                confidentiality. All samples sent to our laboratory are processed solely for
                the purpose of analysis. Upon completion of testing, the samples are destroyed
                using an accredited medical waste disposal company, ensuring safe and
                compliant disposal in line with industry regulations.
              </p>
              <p className="privacy-page__copy">
                We do not sell, share, or pass your test results or personal data to any third
                parties. All test results are securely stored on our systems for a maximum
                period of one year. This allows clients the opportunity to request duplicates
                or perform retests during that time. After one year, the results are
                permanently deleted to protect your privacy. Our secure storage practices
                follow strict data protection protocols to ensure your information remains
                confidential.
              </p>
              <h3 className="privacy-page__header">5. Disclosures of your personal data</h3>
              <p className="privacy-page__copy">
                We may have to share your personal data with the parties set out below for the
                purposes set out in the table in paragraph 4 above.
              </p>
              <ul className="privacy-page__copy">
                <li>
                  Internal Third Parties. Other companies in the group acting as joint
                  controllers or processors.
                </li>
                <li>
                  External Third Parties
                  <ul>
                    <li>
                      Service providers based in the United Kingdom who provide IT and system
                      administration services.
                    </li>
                    <li>
                      Professional advisers acting as processors or joint controllers
                      including lawyers, bankers, auditors and insurers based in the United
                      Kingdom who provide consultancy, banking, legal, insurance and
                      accounting services.
                    </li>
                    <li>
                      HM Revenue &amp; Customs, regulators and other authorities acting as
                      processors or joint controllers based in the United Kingdom who require
                      reporting of processing activities in certain circumstances.
                    </li>
                  </ul>
                </li>
                <li>Specific third parties listed in the table in paragraph 3.3 above.</li>
                <li>
                  Third parties to whom we may choose to sell, transfer, or merge parts of our
                  business or our assets. Alternatively, we may seek to acquire other
                  businesses or merge with them. If a change happens to our business, then the
                  new owners may use your personal data in the same way as set out in this
                  privacy notice.
                </li>
              </ul>
              <p className="privacy-page__copy">
                We require all third parties to respect the security of your personal data and
                to treat it in accordance with the law. We do not allow our third-party
                service providers to use your personal data for their own purposes and only
                permit them to process your personal data for specified purposes and in
                accordance with our instructions.
              </p>
              <h3 className="privacy-page__header">6. International transfers</h3>
              <p className="privacy-page__copy">
                We do not transfer your personal data outside the European Economic Area
                (EEA).
              </p>
              <p className="privacy-page__copy">
                Whenever we transfer your personal data out of the EEA, we ensure a similar
                degree of protection is afforded to it by ensuring at least one of the
                following safeguards is implemented:
              </p>
              <ul className="privacy-page__copy">
                <li>
                  We will only transfer your personal data to countries that have been deemed
                  to provide an adequate level of protection for personal data by the European
                  Commission. For further details, see European Commission: Adequacy of the
                  protection of personal data in non-EU countries.
                </li>
                <li>
                  Where we use providers based in the US, we may transfer data to them if they
                  are part of the Privacy Shield which requires them to provide similar
                  protection to personal data shared between Europe and the US. For further
                  details, see European Commission: EU-US Privacy Shield.
                </li>
              </ul>
              <p className="privacy-page__copy">
                Please Contact us if you want further information on the specific mechanism
                used by us when transferring your personal data out of the EEA.
              </p>
              <h3 className="privacy-page__header">7. Data security</h3>
              <p className="privacy-page__copy">
                We have put in place appropriate security measures to prevent your personal
                data from being accidentally lost, used or accessed in an unauthorised way,
                altered or disclosed. In addition, we limit access to your personal data to
                those employees, agents, contractors and other third parties who have a
                business need to know. They will only process your personal data on our
                instructions and they are subject to a duty of confidentiality
              </p>
              <p className="privacy-page__copy">
                We have put in place procedures to deal with any suspected personal data
                breach and will notify you and any applicable regulator of a breach where we
                are legally required to do so.
              </p>
              <h3 className="privacy-page__header">8. Data retention</h3>
              <p className="privacy-page__copy"><strong>How long will you use my personal data for?</strong></p>
              <p className="privacy-page__copy">
                We will only retain your personal data for as long as necessary to fulfil the
                purposes we collected it for, including for the purposes of satisfying any
                legal, accounting, or reporting requirements.
              </p>
              <p className="privacy-page__copy">
                To determine the appropriate retention period for personal data, we consider
                the amount, nature, and sensitivity of the personal data, the potential risk
                of harm from unauthorised use or disclosure of your personal data, the
                purposes for which we process your personal data and whether we can achieve
                those purposes through other means, and the applicable legal requirements.
              </p>
              <p className="privacy-page__copy">
                Details of retention periods for different aspects of your personal data are
                available in our retention policy which you can request from us by contacting
                us.
              </p>
              <p className="privacy-page__copy">
                In some circumstances you can ask us to delete your data: see Request erasure
                below for further information.
              </p>
              <p className="privacy-page__copy">
                In some circumstances we may anonymise your personal data (so that it can no
                longer be associated with you) for research or statistical purposes in which
                case we may use this information indefinitely without further notice to you
              </p>
              <h3 className="privacy-page__header">9. Your legal rights</h3>
              <p className="privacy-page__copy">
                Under certain circumstances, you have rights under data protection laws in
                relation to your personal data. Your legal rights are:
              </p>
              <ul className="privacy-page__copy">
                <li>
                  Request access to your personal data (commonly known as a “data subject
                  access request”). This enables you to receive a copy of the personal data we
                  hold about you and to check that we are lawfully processing it.
                </li>
                <li>
                  Request correction of the personal data that we hold about you. This enables
                  you to have any incomplete or inaccurate data we hold about you corrected,
                  though we may need to verify the accuracy of the new data you provide to us.
                </li>
                <li>
                  Request erasure of your personal data. This enables you to ask us to delete
                  or remove personal data where there is no good reason for us continuing to
                  process it. You also have the right to ask us to delete or remove your
                  personal data where you have successfully exercised your right to object to
                  processing (see below), where we may have processed your information
                  unlawfully or where we are required to erase your personal data to comply
                  with local law. Note, however, that we may not always be able to comply with
                  your request of erasure for specific legal reasons which will be notified to
                  you, if applicable, at the time of your request.
                </li>
                <li>
                  Object to processing of your personal data where we are relying on a
                  legitimate interest (or those of a third party) and there is something about
                  your particular situation which makes you want to object to processing on
                  this ground as you feel it impacts on your fundamental rights and freedoms.
                  You also have the right to object where we are processing your personal data
                  for direct marketing purposes. In some cases, we may demonstrate that we
                  have compelling legitimate grounds to process your information which
                  override your rights and freedoms.
                </li>
                <li>
                  Request restriction of processing of your personal data. This enables you to
                  ask us to suspend the processing of your personal data in the following
                  scenarios: (a) if you want us to establish the data’s accuracy; (b) where
                  our use of the data is unlawful but you do not want us to erase it; (c)
                  where you need us to hold the data even if we no longer require it as you
                  need it to establish, exercise or defend legal claims; or (d) you have
                  objected to our use of your data but we need to verify whether we have
                  overriding legitimate grounds to use it.
                </li>
                <li>
                  Request the transfer of your personal data to you or to a third party. We
                  will provide to you, or a third party you have chosen, your personal data in
                  a structured, commonly used, machine-readable format. Note that this right
                  only applies to automated information which you initially provided consent
                  for us to use or where we used the information to perform a contract with
                  you.
                </li>
                <li>
                  Withdraw consent at any time where we are relying on consent to process your
                  personal data. However, this will not affect the lawfulness of any
                  processing carried out before you withdraw your consent. If you withdraw
                  your consent, we may not be able to provide certain products or services to
                  you. We will advise you if this is the case at the time you withdraw your
                  consent.
                </li>
              </ul>
              <p className="privacy-page__copy">
                If you wish to exercise any of the rights set out in paragraph 9.1 above,
                please contact us.
              </p>
              <p className="privacy-page__copy"><strong>Your rights </strong></p>
              <p className="privacy-page__copy">Being the data subject, you have the following rights according to:</p>
              <p className="privacy-page__copy">
                • art. 15 GDPR, the right to obtain information about your personal data which
                we process, within the scope described therein;
              </p>
              <p className="privacy-page__copy">
                •art. 16 GDPR, the right to immediately demand rectification of incorrect or
                completion of your personal data stored by us;
              </p>
              <p className="privacy-page__copy">
                • art. 17 GDPR, the right to request erasure of your personal data stored with
                us, unless further processing is required – to exercise the right of freedom
                of expression and information; – for compliance with a legal obligation; – for
                reasons of public interest or – for establishing, exercising or defending
                legal claims;
              </p>
              <p className="privacy-page__copy">
                • art. 18 GDPR, the right to request restriction of processing of your
                personal data, insofar as – the accuracy of the data is contested by you; –
                the processing is unlawful, but you refuse their erasure; – we no longer need
                the data, but you need it to establish, exercise or defend legal claims, or –
                you have lodged an objection to the processing in accordance with art. 21
                GDPR;
              </p>
              <p className="privacy-page__copy">
                • art. 20 GDPR, the right to receive your personal data that you have provided
                to us in a&nbsp;structured, commonly used and machine-readable format or to
                request its transmission to another controller;
              </p>
              <p className="privacy-page__copy">
                • art. 77 GDPR, the right to complain to a supervisory authority. As a rule,
                you can contact the supervisory authority at your habitual place of residence
                or workplace or at our company headquarters.
              </p>
              <p className="privacy-page__copy"><strong>No fee usually required.</strong></p>
              <p className="privacy-page__copy">
                You will not have to pay a fee to access your personal data (or to exercise
                any of the other rights). However, we may charge a reasonable fee if your
                request is clearly unfounded, repetitive or excessive. Alternatively, we may
                refuse to comply with your request in these circumstances.
              </p>
              <p className="privacy-page__copy"><strong>What we may need from you.</strong></p>
              <p className="privacy-page__copy">
                We may need to request specific information from you to help us confirm your
                identity and ensure your right to access your personal data (or to exercise
                any of your other rights). This is a security measure to ensure that personal
                data is not disclosed to any person who has no right to receive it. We may
                also contact you to ask you for further information in relation to your
                request to speed up our response.
              </p>
              <p className="privacy-page__copy"><strong>Time limit to respond.</strong></p>
              <p className="privacy-page__copy">
                We try to respond to all legitimate requests within one month. Occasionally it
                may take us longer than a month if your request is particularly complex or you
                have made a number of requests. In this case, we will notify you and keep you
                updated.
              </p>
              <h3 className="privacy-page__header">10. Glossary</h3>
              <p className="privacy-page__copy"><strong>LAWFUL BASIS</strong></p>
              <p className="privacy-page__copy">
                Legitimate Interest means the interest of our business in conducting and
                managing our business to enable us to give you the best service/product and
                the best and most secure experience. We make sure we consider and balance any
                potential impact on you (both positive and negative) and your rights before we
                process your personal data for our legitimate interests. We do not use your
                personal data for activities where our interests are overridden by the impact
                on you (unless we have your consent or are otherwise required or permitted to
                by law). You can obtain further information about how we assess our legitimate
                interests against any potential impact on you in respect of specific
                activities by contacting us.
              </p>
              <p className="privacy-page__copy">
                Performance of Contract means processing your data where it is necessary for
                the performance of a contract to which you are a party or to take steps at
                your request before entering into such a contract.
              </p>
              <p className="privacy-page__copy">
                Comply with a legal or regulatory obligation means processing your personal
                data where it is necessary for compliance with a legal or regulatory
                obligation that we are subject to.
              </p>
              <p className="privacy-page__copy">
                The European Commission provides a platform for online dispute resolutions
                (ODR) which can be accessed at http://ec.europa.eu/consumers/odr/. We do not
                intend to participate in dispute settlement proceedings before a dispute
                resolution body.
              </p>
             <p className="privacy-page__copy">Last Updated: 5th November 2024</p>

 
         </section>
     );
 };
 
 export default PrivacyPolicyPage;
 
