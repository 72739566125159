/**
 * Blood Form component.
 * Takes form data and validates it.
 * If successful, an api request is sent to './api/BloodSubmission.php'.
 * 
 * @method validateInput : (string) => boolean
 * @method validateEmail : (string) => boolean
 * @method validateCodeLength : (string) => boolean
 * @method queryLIMS : async () => void
 * @method formatResults : (QueryInterface) => void
 * @method submitHandler : (React.FormEvent) => void
 * 
 * @returns ActivationCode : PureComponent
 */

 import React, { useEffect, useRef, useState } from "react";
 import { NavLink, useNavigate } from "react-router-dom";
 import { BsArrowLeftSquareFill } from "react-icons/bs";
 import ReCAPTCHA from "react-google-recaptcha";
 import "./AmazonBloodForm.scss";
 import PhoneInput from 'react-phone-input-2';
 import 'react-phone-input-2/lib/style.css';
 import Checkbox from "../inputs/Checkbox";
 
 const liveEndpoint = "https://activatemytest.com/api/controllers/BloodSubmission.php";
 // const xamppEndpoint = "http://localhost:80/activatemytest/api/controllers/BloodSubmission.php";
 
 // Interfaces for the results we get
 interface SubmissionResponse {
     success : boolean,
     message : string
 };
 
 interface QueryInterface {
     codeActivated : boolean | null,
     codeExists : boolean,
     error : boolean,
     message : string,
     orderValid : boolean,
     result : SubmissionResponse | null
 };
 
 interface QueryResult {
     error : boolean,
     result : QueryInterface | null,
     qrCodeSaved : boolean,
     location : string
 };
 
 // Check the prefix against the array of partners
 const checkPrefix = (prefix : string) => {
 
     // Partners prefixes
     const partners = [
         "AA"
     ];
 
     // Get a list of filtered partners, if the length is > 0, return true, otherwise return false
     const filteredPartners = partners.filter((partner : string) => {
         return partner === prefix.toUpperCase();
     });
 
     if (filteredPartners.length > 0) {
         return true;
     }
 
     return false;
 
 };
 
 const BloodForm = () => {
 
     // navigate to redirect on form submission
     const navigate = useNavigate();
     
     // Input refs
     const firstNameRef = useRef<HTMLInputElement>(null);
     const lastNameRef = useRef<HTMLInputElement>(null);
     const emailRef = useRef<HTMLInputElement>(null);
     const orderNumberRef = useRef<HTMLInputElement>(null);
     const activationCodeRef = useRef<HTMLInputElement>(null);
     const partnerCodeRef = useRef<HTMLInputElement>(null);
     const reCaptchaRef = useRef<ReCAPTCHA>(null);
     const genderRef = useRef<HTMLSelectElement>(null);
 
     // Form validation states
     const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
     const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);
     const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
     const [isOrderNumberValid, setIsOrderNumberValid] = useState<boolean>(true); 
     const [isActivationCodeValid, setIsActivationCodeValid] = useState<boolean>(true);
     const [isPartnerCodeValid, setIsPartnerCodeValid] = useState<boolean>(true);
     const [activationCodeErrorMsg, setActivationCodeErrorMsg] = useState<string>("");
     const [showPopup, setShowPopup] = useState<boolean>(false);
     const [formSuccess, setFormSuccess] = useState<boolean>(false);
     const [isFormValid, setIsFormValid] = useState<boolean>(false);
     const [showPartnerField, setShowPartnerField] = useState<boolean>(false);
     const [isReCaptchaValid, setIsReCaptchaValid] = useState<boolean>(false);
     const [phoneNumber, setPhoneNumber] = useState<string>("");
     const [consentChecked, setConsentChecked] = useState<boolean>(false);
 
     // Toggle checkbox
     const handleCheckboxToggle = () => {
 
         setConsentChecked(!consentChecked);
     };
 
     // Validate our email address as being valid by checking it against regular expression
     const validateEmail = (email : string) => {
         return /^[^@]+@[^@]+\.[^@]+$/.test(email);
     };
 
     // Make sure the input is not empty
     const validateInput = (input : string) => {
         return input.length > 0;
     };
 
     // Make sure the product length is 2 characters
     const validatePartnerCode = (input : string) => {
         return input.length === 2;
     };
  
     // Make sure that our activation code is at least 8 characters long
     const validateCodeLength = (code : string) => {
 
         if (code.length >= 8){
 
             setActivationCodeErrorMsg("");
             return true;
 
         }else{
 
             setActivationCodeErrorMsg("Error: Activation code must be at least 8 characters long");
             return false;
 
         }
     };
 
     // Determine if we show the partner code dynamically based on the current length of the activation code
     const checkIfPartner = () => {
         
         if (activationCodeRef.current && activationCodeRef.current.value.length >= 2) {
 
             // Get a substring and check the refix in order to show or hide the partner code field
             const prefix = activationCodeRef.current.value.substring(0, 2);
             setShowPartnerField(checkPrefix(prefix));
         }else{
             setShowPartnerField(false);
         }
 
     };
 
     // Submit our form to the backend of LIMS using an api request, if it's successful, we'll toggle a pop up to let the customer know that they were successful
     const queryLIMS = async () => {
 
         const token = String(reCaptchaRef.current?.getValue());
 
         const fields = new FormData();     
         firstNameRef.current && fields.append("firstName", firstNameRef.current.value); 
         lastNameRef.current && fields.append("lastName", lastNameRef.current.value);
         emailRef.current && fields.append("email", emailRef.current.value);
         orderNumberRef.current && fields.append("orderNumber", orderNumberRef.current.value);
         activationCodeRef.current && fields.append("activationCode", activationCodeRef.current.value);
         partnerCodeRef.current && fields.append("partnerCode", partnerCodeRef.current.value ? partnerCodeRef.current.value : "");
         reCaptchaRef.current && fields.append("recaptchaToken", token);
         genderRef.current && fields.append("gender", genderRef.current.value);
         fields.append("phoneNumber", phoneNumber ? phoneNumber : "");
 
         // Perform API request
         // Note: Do not pass a content type through to this request as the browser completes for you as well as your boundary
         const response = await fetch(liveEndpoint, {
             method: "POST",
             body: fields
         });
 
         const data = await response.json();
 
         console.log(data);
 
         formatResults(data);
 
     }
 
     // Format the results for the popup responses
     const formatResults = (response : QueryResult) => {
 
         const result = response.result;
 
         let orderValid, codeExists, codeActivated, tokenValid : boolean = false;
 
         console.log("\n");
 
         // Set order valid boolean
         // orderValid = result?.orderValid ? result?.orderValid : false;
         orderValid = true;
 
         // Set activation code booleans
         codeExists = result?.codeExists;
         codeActivated = result?.codeActivated ? result?.codeActivated : false;
 
         // Set order validity state
         setIsOrderNumberValid(orderValid);
 
         // Set activation code validity state
         setIsActivationCodeValid(() => {
 
             // If our code doesn't exist
             if (result?.codeExists === false){
                 setActivationCodeErrorMsg("Error: Activation code doesn't exist.");
                 return false;
             }
 
             if (result?.codeActivated === true) {
                 setActivationCodeErrorMsg("Error: Activation code has already been used.");
                 return false;
             }
 
             if (response.qrCodeSaved === false) {
                 setActivationCodeErrorMsg("Error: You cannot submit more activation codes. Please contact customer support");
                 return false;
             }
 
             setActivationCodeErrorMsg("");
             return true;
 
         });
 
         if (response.location === "recaptcha" && response.error === true){
 
             tokenValid = false;
             setIsReCaptchaValid(tokenValid);
         }else{
 
             tokenValid = true;
             setIsReCaptchaValid(tokenValid);
         }
 
         if (orderValid === true && codeExists === true && codeActivated === false && tokenValid === true) {
             
             // Set the form to green
             setFormSuccess(true);
 
             // Reset re-captcha
             reCaptchaRef.current?.reset();
 
             // Navigate to the blood thank you page
             navigate(`/thank-you-blood/`);
 
         }
 
     }
 
     // On submission, set the isSubmitted state to true, and then execute validation on the fields. All fields are now automatically validated.
     const submitHandler = (event : React.FormEvent) => {
 
         // Stop the page from reloading
         event.preventDefault();
 
         // We define local values here because we need to trigger a re-render by updating state which means that we won't be able to access state validation
         let validFirstName, validLastName, validEmail, validActivationCode, validOrderNumber, validPartnerCode, tokenValid : boolean = true;
          
         // Validate our inputs
         if (firstNameRef.current) {
             validFirstName = validateInput(firstNameRef.current.value);
             setIsFirstNameValid(validFirstName);
         }
 
         if (lastNameRef.current) {
             validLastName = validateInput(lastNameRef.current.value);
             setIsLastNameValid(validLastName);
         }
 
         if (emailRef.current) {
             validEmail = validateEmail(emailRef.current.value)
             setIsEmailValid(validEmail);
         }
 
         // Check the length of the activation code pre submission
         if (activationCodeRef.current) {
             validActivationCode = validateCodeLength(activationCodeRef.current.value);
             setIsActivationCodeValid(validActivationCode);
         }
 
         if (orderNumberRef.current) {
             validOrderNumber = validateInput(orderNumberRef.current.value);
             setIsOrderNumberValid(validOrderNumber);
         }
 
         if (partnerCodeRef.current) {
             validPartnerCode = validatePartnerCode(partnerCodeRef.current.value);
             setIsPartnerCodeValid(validPartnerCode);
         }
 
         if (reCaptchaRef.current) {
             const token = reCaptchaRef.current.getValue();
             
             console.log("token", token);
 
             // If token isn't null ( which it won't be )
             if (token) {
 
                 tokenValid = validateInput(token);
                 setIsReCaptchaValid(tokenValid);
 
             }else{
 
                 setIsReCaptchaValid(false);
 
             }
         }
 
         if (validFirstName && validLastName && validEmail && validActivationCode && tokenValid && validOrderNumber) {
 
             // If we haven't successfully submitted an activation form, submit one. We do this to prevent spamming the submit button
             if (formSuccess === false) {
                 console.log("Form event submission");
                 queryLIMS();
             }
 
         }
 
         setShowPopup(true);
 
     };
  
     // Check if the form is valid for the popup
     useEffect(() => {
 
         // If our inputs are valid, then set the pop up to show success text
         if (isOrderNumberValid === true && isFirstNameValid === true && isLastNameValid === true && isEmailValid === true && isActivationCodeValid === true && isPartnerCodeValid === true && isReCaptchaValid === true) {
             setIsFormValid(true);
         }else{
             setIsFormValid(false);
         }
 
     },[isOrderNumberValid, isFirstNameValid, isLastNameValid, isEmailValid, isActivationCodeValid, isPartnerCodeValid, isReCaptchaValid]);
 
     return(
         <section className="blood-form-wrapper">
 
             <NavLink to="/amazon" className="blood-form-link"><BsArrowLeftSquareFill/>Go Back</NavLink>
             
             { showPopup && 
                 <p className="blood-form-popup">
                     {isFormValid ? "Success! Refresh the page if you wish to submit another code " : "Please fix the errors found below"}
                 </p>
             }
 
             <form className="blood-form" onSubmit={submitHandler}>
 
                 <p className="blood-form__title">Activate Your Test Kit</p>
 
                 <div className={`blood-form__field ${ !isFirstNameValid && "blood-form__field--error" }`}>
                     <label id="first_name_label" htmlFor="first_name" className="blood-form__label">{ isFirstNameValid ? "First Name*" : "Error: First name is empty" }</label>
                     <input
                         type="text"
                         name="first_name"
                         aria-labelledby="first_name_label"
                         placeholder="Please enter your first name..."
                         aria-required
                         className={`blood-form__input ${ !isFirstNameValid && "blood-form__input--error" }`}
                         ref={firstNameRef}
                     />
                 </div>
 
                 <div className={`blood-form__field ${ !isLastNameValid && "blood-form__field--error" }`}>
                     <label id="last_name_label" htmlFor="last_name" className="blood-form__label">{ isLastNameValid ? "Last Name*" : "Error: Last name is empty" }</label>
                     <input
                         type="text"
                         name="last_name"
                         aria-labelledby="last_name_label"
                         placeholder="Please enter your last name..."
                         aria-required
                         className={`blood-form__input ${ !isLastNameValid && "blood-form__input--error" }`}
                         ref={lastNameRef}
                     />
                 </div>
 
                 <PhoneInput
                     country={'gb'}
                     value={phoneNumber}
                     onChange={setPhoneNumber}
                 />
 
                 <div className="blood-form__field">
                     <label id="gender_label" htmlFor="gender" className="blood-form__label">Gender</label>
                     <select 
                         name="gender" 
                         aria-labelledby="gender_label" 
                         ref={genderRef} 
                         defaultValue="default" 
                         className="blood-form__dropdown"
                         style={{ 
                             background: "url('https://lims.healthystuff.com/arrow-down.svg')",
                             backgroundSize: "18px",
                             backgroundRepeat : "no-repeat",
                             backgroundPositionY : "50%",
                             backgroundPositionX : "calc(100% - 5px)"
                         }}
                     >
                         <option key="default" value={"default"} disabled>Choose Gender</option>
                         <option key="male" value={"Male"}>Male</option>
                         <option key="female" value={"Female"}>Female</option>
                         <option key="other" value={"Other"}>Other</option>
                         <option key="neither" value={"Prefer not to say"}>Prefer not to say</option>
                     </select>
                 </div>
 
                 <div className={`blood-form__field ${ !isEmailValid && "blood-form__field--error" }`}>
                     <label id="email_address_label" htmlFor="email_address" className="blood-form__label">{ isEmailValid ? "Email Address*" : "Error: email address isn't valid" }</label>
                     <input
                         type="email"
                         name="email_address"
                         aria-labelledby="email_address_label"
                         aria-required
                         placeholder="Please enter your email address..."
                         className={`blood-form__input ${ !isEmailValid && "blood-form__input--error" }`}
                         ref={emailRef}
 
                     />
                 </div>
 
                 <div className={`blood-form__field ${!isOrderNumberValid ? "blood-form__field--error" : ""}`}>
                     <label id="order_number_label" htmlFor="order_number" className="blood-form__label">
                     {isOrderNumberValid ? "Amazon Order Number*" : "Error: Order number isn't valid"}
                     </label>
                          <input
                             type="text"
                             name="order_number"
                             aria-required
                             placeholder="Enter your order number"
                             className={`blood-form__input ${!isOrderNumberValid && "blood-form__input--error"}`}
                             ref={orderNumberRef}
                             autoComplete="new-password"
                         />
                     </div>
 
 
                 {   showPartnerField &&
                     <div className={`blood-form__field ${ !isPartnerCodeValid && "blood-form__field--error" }`}>
                         <label id="partner_code_label" htmlFor="partner_code" className="blood-form__label">{ isPartnerCodeValid ? "Partner Code" : "Error: Partner code needs to be 2 characters long" }</label>
                         <input
                             type="text"
                             name="partner_code"
                             aria-labelledby="partner_code"
                             aria-required
                             placeholder="Please enter your partner code..."
                             className={`blood-form__input ${ !isPartnerCodeValid && "blood-form__input--error" }`}
                             ref={partnerCodeRef}
                             defaultValue=""
                         />
                     </div>
                 }
 
                 <div className={`blood-form__field ${ !isActivationCodeValid && "blood-form__field--error"}`}>
                     <label id="activation_code_label" htmlFor="activation_code" className="blood-form__label">{ activationCodeErrorMsg.length > 0 ? activationCodeErrorMsg : "Activation Code (Found on your test kit)*" }</label>
                     <input
                         type="text"
                         name="activation_code"
                         aria-labelledby="activation_code_label"
                         aria-required
                         placeholder="Please enter your activation code..."
                         className={`blood-form__input activation-code-input ${ !isActivationCodeValid && "blood-form__input--error" }`}
                         onChange={checkIfPartner}
                         ref={activationCodeRef}
                         maxLength={10}
                     />
                 </div>
 
                 <Checkbox
                     isChecked={consentChecked}
                     label="I agree to have my data used for research and publication purposes."
                     handleChange={handleCheckboxToggle}
                 />
 
                 {
                     showPopup && (isReCaptchaValid === false) &&
                     <p className="blood-form__recaptcha-error">Error: ReCaptcha is invalid</p>
                 }
                 <ReCAPTCHA
                     sitekey="6Ld_2pskAAAAAPFePhfrk2K7T3mVksszVs9XF16X"
                     ref={reCaptchaRef}
                 />
 
                 <button className="blood-form__submit">Submit</button>
 
             </form>
         </section>
     );
 };
 
 export default BloodForm;
 